@charset "UTF-8";
/*================================================
このCSSファイルはSassから生成されています。
編集しないようにご注意ください。
================================================*/
//ブレイクポイントのmixin
@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

html {
  font-size: 62.5%;
  /* 10px */
  height: 100%;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  display: block;
}

/*cssスライド*/
.slide_wrap {
  margin-top: 57px;
  position: relative;
}

.image-crossfader {
  overflow: hidden;
  position: relative;
  height: 604px;
}
.image-crossfader-inner {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  transition: opacity 3.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.image-crossfader-inner.is-visible {
  z-index: 1;
  opacity: 1;
}

.image-crossfader-inner:nth-child(1) {
  background-image: url(../../top/img/slide01.jpg);
}
.image-crossfader-inner:nth-child(2) {
  background-image: url(../../top/img/slide02.jpg);
}
.image-crossfader-inner:nth-child(3) {
  background-image: url(../../top/img/slide03.jpg);
}
.image-crossfader-inner:nth-child(4) {
  background-image: url(../../top/img/slide04.jpg);
}

//トップコピー
.copy_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  text-align: center;
}

.main_copy-logo {
  width: 237px;
  margin-left: auto;
  margin-right: auto;
}

.top_copy {
  text-align: center;
  color: #fff;
  font-weight: normal;
  font-size: 19px;
  font-family: 'Noto Serif JP', serif;
  margin-top: 18px;
}

#ABOUT {
  margin-top: 80px;
}

.taitle_main {
  color: #B8A577;
  font-weight: 500;
  font-size: 2.6rem;
  font-family: 'Cormorant Garamond', serif;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    color: #333;
  }
}

.taitle_main::before, .taitle_main::after {
  display: block;
  -webkit-box-flex: 0;
  flex: 0 0 40px;
  content: '';
  border-top: solid 1px #B8A577;
  margin-right: 10px;
}

.taitle_sub {
  color: #333;
  font-family: sans-serif;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.05em;
  padding-top: 3px;
}

.main_copy_box {
  position: relative;
  padding-top: 44px;
  &::after {
    position: absolute;
    display: block;
    content: '';
    background: url(../../top/img/bg_logo.svg) center no-repeat;
    background-size: contain;
    width: 278px;
    height: 306px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}

.main_copy {
  color: #333;
  font-family: 'Noto Serif JP', serif;
  text-align: center;
  font-size: 14px;
  line-height: 2.2;
  &:nth-of-type(2) {
    padding-top: 80px;
  }
}

#SERVICE {
  margin-top: 94px;
}

.link_box {
  padding-top: 44px;
}

.store_link {
  a {
    color: #fff;
  }
}

.store_name {
  text-align: center;
  font-family: 'Noto Serif JP', serif;
  font-size: 14px;
  padding: 8px 0;
  &._la {
    background-color: #84454e;
  }
  &._fq {
    background-color: #f2c81d;
  }
  &._la-w {
    background-color: #b8a577;
  }
  span {
    position: relative;
    padding-right: 28px;
    &::after {
      position: absolute;
      display: block;
      content: '';
      background: url(../../top/img/extra_link-w.svg) center no-repeat;
      background-size: contain;
      width: 16px;
      height: 14px;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
}

.news_bg {
  background-color: #f6f6f6;
  box-sizing: border-box;
  padding: 55px 0;
}

.taitle_news {
  font-family: sans-serif;
  color: #252422;
  font-size: 19px;
  letter-spacing: .16em;
  font-weight: normal;
  span {
    font-size: 11px;
    display: block;
    letter-spacing: 0;
    padding-top: 3px;
  }
}

.news_wrap {
  background-color: #fff;
  font-family: sans-serif;
  padding: 15px 10px;
  box-sizing: border-box;
  height: 135px;
  overflow: scroll;
  margin-top: 29px;
}

.news_box {
  font-size: 12px;
  border-bottom: 1px dotted #ccc;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
  dt {
    color: #333;
    font-family: sans-serif;
  }
  dd {
    line-height: 1.5;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    a {
      color: #333;
    }
  }
}

.banner_box {
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 37px;
}

.banner_box {
  li {
    &:not(:first-of-type) {
      margin-top: 22px;
    }
  }
}

.new_info {
  color: #ff0000;
  font-size: 12px;
  padding-left: 1.3em;
}

.news {
  font-family: "Noto Serif JP", serif;
  color: #333;
  margin-left: 5%;
  margin-right: 5%;
  dt {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 24px;
    padding-top: 56px;
  }
  dd {
    font-size: 13px;
    line-height: 1.8;
    span {
      font-weight: bold;
    }
    &:not(:first-of-type) {
      padding-top: 16px;
    }
  }
}

.notice {
font-family: sans-serif;
  color: #333;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 36px;
  dt {
    font-size: 15px;
    text-align: center;
    padding-bottom: 12px;
  }
  dd {
    line-height: 1.8;
    font-size: 13px;
    text-align: justify;
    &:not(:first-of-type) {
      padding-top: 12px;
    }
  }
}